import axiosRetry from 'axios-retry';

export const DEFAULT_RETRY_STRATEGY = {
  retries: 1, // number of retries
  retryDelay: (retryCount) => {
    // eslint-disable-next-line no-console
    console.debug(`Retrying request (Attempt ${retryCount})`);
    return axiosRetry.exponentialDelay;
  },
  retryCondition: (error) => {
    // if retry condition is not specified, by default idempotent requests are retried
    return [503].includes(error.response.status);
  },
};

export const CORRELATION_ID_HEADER_KEY = 'X-Trv-Correlation-ID';
export const SESSION_ID_HEADER_KEY = 'X-Trv-Session-ID';
export const USER_TOKEN_HEADER_KEY = 'x-trv-usertoken';
export const SMSESSION_HEADER_KEY = 'smsession';

// Media size constants
export const OPERATOR_TABLE_MARGIN_ADJUST = 1540;
export const DESKTOP_MIN_MEDIA_WIDTH = 1417;
export const TABLET_MAX_MEDIA_WIDTH = 1024;
export const PHONE_MAX_MEDIA_WIDTH = 575;

// UI constants
export const TRAV_ACORD_VERSION_1 = '1.0';
export const TRAV_ACORD_VERSION_2 = '2.0';
export const AUTO = 'Auto';
export const PERSONAL_ARTICLE = 'Personal_Article';
export const UMBRELLA = 'Umbrella';
export const DWELLING = 'Home';
export const HOMECARDSINCLUDEFORDATE = ['CONDO', 'DWELLING', 'TENANT'];
export const HOMECARDSEXCLUDEFORDATE = ['LANDLORDCONDO', 'LANDLORDDWELLING'];
export const HOME = 'Home';
export const RENTERS = 'TENANT';
export const HOMECARD = 'CONDO';
export const DQPCUSTOM_INTELLIDRIVEWITHDAD = 'DQPCUSTOM_INTELLIDRIVEWITHDAD';
export const DQPCUSTOM_INTELLIDRIVEPLUSWITHDAD =
  'DQPCUSTOM_INTELLIDRIVEPLUSWITHDAD';
export const DQPCUSTOM_INTELLIDRIVEPLUS = 'DQPCUSTOM_INTELLIDRIVEPLUS';
export const DQPCUSTOM_INTELLIDRIVE3 = 'DQPCUSTOM_INTELLIDRIVE3';
export const DQPCUSTOM_INTELLIDRIVE = 'DQPCUSTOM_INTELLIDRIVE';
export const APPLIED_DISCOUNT = 'applied';
export const AVAILABLE_DISCOUNT = 'available';
export const EFT = 'Electronic Funds Transfer';
export const CREDIT_CARD = 'Recurring Credit Card';
export const BILL_BY_MAIL = 'Bill By Mail/Email';
export const MONTHLY = 'Monthly';
export const PAY_IN_FULL = 'Pay In Full';
export const MORTGAGEE_BILL = 'Mortgagee Bill';
export const TAXES_AND_FEES = 'Taxes and Fee';
export const ALTERNATE_PAYOR = 'Alternate Payor';
export const REFUND_MORTGAGEE = 'Refund To First Mortgagee';
export const SERVICE_CHARGE = '$0.00';
export const ADD_OPTIONS = 'addOptions';
export const COMMENT = 'comment';
export const UPSELL = 'upSell';
export const DA = 'DA';
export const IN = 'IN';
export const ID3 = 'ID3';
export const ADDITIONAL_COVERAGE = 'additionalCoverageType';
export const PAYMENT_OPTIONS = 'paymentOptions';
export const PROMO = 'promo';
export const DISCRETE = 'DISCRETE';
export const SUBLIMIT = 'Sublimit';
export const PERCENT = 'PERCENT';
export const TRECVG = 'TRECVG';
export const SCPPCU = 'SCPPCU';
export const PROMO_UMBRELLA = 'Umbrella: $1 million in liability';
export const PROMO_HOME = 'Home Multi-Policy Discount';
export const AUTOP = 'AUTOP';
export const UMBRP = 'UMBRP';
export const INMRP = 'INMRP';
export const BOAT = 'BOAT';
export const YACHT = 'YACHT';
export const DWELLING_ADMIN = 'DWELLING';
export const LANDLORDDWELLING = 'LANDLORDDWELLING';
export const LANDLORDCONDO = 'LANDLORDCONDO';
export const FEEDBACK_TEXT =
  'Electronic Paperless Delivery of Policy and Billing Documents';
export const PAYMENT_OPTIONS_LEARN_MORE =
  'Customer wants to learn more about their payment options';
export const PAYROLL_DEDUCTION = 'Payroll Deduction';
export const NONE = 'none';
export const AISAP = 'AISAP';
export const CONFIRM_QUOTE = 'confirmQuote';
export const PERSONAL_ARTICLES_POLICY = 'Personal Articles Policy';
export const JEWELRY = 'Jewelry';
export const COOPERATIVE = 'Cooperative';
export const quoteid = ':quoteid';
export const PROMO_PAF = 'Personal Article Multi-Policy Discount';
export const PROMO_AUTO = 'Auto Multi-Policy Discount';
export const PROMO_CONNECTED_CARD =
  'Interest in IntelliDrive via Connected Vehicles';
// -----------Property Coverage Codes----------------
export const COVERAGE_CODE_DWELL = 'dwell';
export const COVERAGE_CODE_PP = 'pp';
export const COVERAGE_CODE_PL = 'pl';
export const COVERAGE_CODE_LOU = 'lou';
export const COVERAGE_CODE_OS = 'os';
export const COVERAGE_CODE_MEDPM = 'medpm';
// -----------Property sublobs-----------------------
export const PROPERTY_SUBLOB_DWELLING = 'dwelling';
export const PROPERTY_SUBLOB_TENANT = 'tenant';
export const PROPERTY_SUBLOB_LANDLORDCONDO = 'landlordcondo';
export const PROPERTY_SUBLOB_LANDLORDDWELLING = 'landlorddwelling';
export const PROPERTY_SUBLOB_CONDO = 'condo';
// -----------Property Deductibles and Property Coverages----------------------
export const AMOUNT_TYPE_DISCRETE = 'discrete';
export const AMOUNT_TYPE_PERCENT = 'percent';
export const AMOUNT_TYPE_MONTHS = 'months';

export const TELESALES = 'TELESALES';
// -----------ThankYou CheckList----------------------
export const PROPERTY = 'Property';
export const BILLING = 'Billing';
export const TOTAL = 'Total:';

// Path constants
export const ROUTES = {
  LANDING_PAGE: '/quotes/:quoteid',
  QUOTELIST_PAGE: '/my-quote/:quoteid',
  DQP_QUOTELIST_PAGE: '/admin',
  RAW_QUOTELIST_PAGE: '/:quoteID/raw',
  THANK_YOU_QUOTES: '/quotes/',
  THANK_YOU_THANKS: '/thanks',
  BOAT_DETAILS_PAGE: '/BoatDetailsPage',
  ERROR: '/error',
};

// env constants
export const API_ROUTE = {
  REACT_APP_PROXY_TARGET_SIMPLE_QUOTE_API_URL: '/api/graphql/execute',
  REACT_APP_SIMPLE_QUOTE_AUTH_URL: '/api/quotes',
};

// JWT Token
export const JWTKEY =
  'MIICXQIBAAKBgQCl4rUMkPLETUaaArPIZ4KicVAV+H++fSO7R9hwTQ3M45pN3mHSagm4zoKeihm0UWHaULuIUn9/syzPLly39bPK+5RDCy4hLwQBB4CpvzmDkzmy9IE7YLdGulrFUromXU9yT0/B9WziyT7+7B04C6z/lidzL71AFrgVy1INSnWIZQIDAQABAoGAGi4QUr5hgfeMJ+w7QWqMpGRrtjv0tTR8uibZksemYOqexKFPIO0ZQbBdzvWOnvWlEtZg8l/KvXWiocP4YBQH3sUBULG8JKWi+UxCmtfOOoSr7zFfLsq34vHJ9Zd+3ADOErKtPFSbC0kkWABiUA2OE8MlGL9eJzHW+m04PfNu0KECQQDW3Eg2dfsMWqYgzuqu6I44zLfeLIj5wyxv9Z7TnCSQPr/keLZ5mrwkvD9UyHpIJc6XYPYwJ6ZXWmwWh0VargbdAkEAxaXYQKNMlEc4BWmrNAe8oFYQkEUZhHb4RBYDCrOwYOBlLPB2yIozzjm0XGQZ0PCfenW0G4GJztsR4OHFPy+7KQJAdP3rICRrt1VgofqpFOYyEsF92K79QqpAdxISEn0x940DZb9I9LwJeCM8hMjItKJJdGnqg1wDmgr1UesEG5UozQJBALigIfDHWgqMuHz950oUDBRuMMzDb4G4rFLViQPfNFxaYMho21ybFKeIab2AWEk/7Ik1GAg1qihLpVi/LUTH7nkCQQCbwt+8kZcZViRBYPTSsFL3pLV+SziIB+xoByTrj5SG3woxIozRrAhXxSveGUerRir/kMjnM5fb3cjvYqNhNAAl';

// EXPIRESIN
export const EXPIRESIN = {
  expiresIn: 86400,
};

// User Type Constants
export const USERTYPE = {
  QUOTESPAGE: ['agent', 'telesales', 'preview', 'test'],
  LANDINGPAGE: ['recipient', 'recipientCopyLink', 'unknown'],
};

// GA Constants
export const GACONSTANTS = {
  GATYPE: 'test',
  Discounts: ['discounts', 'discount-header1', 'discount-header2'],
  Drivers: ['drivers-header', 'drivers'],
  Vehicles: ['vehicles', 'vehicles-header1', 'vehicles-header2'],
  AvailablePayment: ['availablePayment', 'availablePayment-header'],
  PayrollDeduction: ['payrollDeduction', 'payrollDeduction-header'],
  AdditionalCoverages: ['additional-coverage', 'additionalCoverage-header'],
  RatingFactor: ['ratingFactor', 'ratingFactor-header'],
};

// -----Env Constants-------
export const PRODUCTION_ENV = 'production';

// ------Boat Yacht Constants---------

// General Information Constants
export const OVERVIEW_LABEL = 'Boat policy overview';

// Watercraft Card Constants
export const HIN_LABEL = 'HIN:';
export const PREMIUM_LABEL = 'for this watercraft';

// Operator Info Constants
export const OPERATOR_INFO_TITLE = 'Operators listed on this policy';
export const BORN_IN = 'Born in ';
export const LICENSED_POSTFIX = ' driver';
export const OPERATOR_EXPERIENCE = ' years boating experience';
export const DRIVER_STATE = 'Drivers License State is ';
